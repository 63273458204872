import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
import NumberLabel from '../../../components/worksafe-vic/NumberLabel';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "tyro-health-sdk",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tyro-health-sdk",
        "aria-label": "tyro health sdk permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tyro Health SDK`}</h3>
    <p>{`There is a three step process to get you started with the Tyro Health SDK:`}</p>
    <NumberLabel number="1" mdxType="NumberLabel">Set up the transaction SDK</NumberLabel>
    <NumberLabel number="2" mdxType="NumberLabel">Submit transaction details</NumberLabel>
    <NumberLabel number="3" mdxType="NumberLabel">Record Transaction Outcome </NumberLabel>
    <h4 {...{
      "id": "numberlabel-number1set-up-the-transaction-sdknumberlabel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#numberlabel-number1set-up-the-transaction-sdknumberlabel",
        "aria-label": "numberlabel number1set up the transaction sdknumberlabel permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><NumberLabel number="1" mdxType="NumberLabel">{`Set up the transaction SDK`}</NumberLabel></h4>
    <h5 {...{
      "id": "requirements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#requirements",
        "aria-label": "requirements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Requirements:`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Requirement`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Notes`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`API KEY`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An API Key  grants you access to a business and all related providers resources.`}<br />{`API Keys can only be created by a business admin. `}<br />{` How to obtain an API Key:`}<br />{`Once the admin is logged into the `}<a parentName="td" {...{
              "href": "https://connect.medipass.io/auth?forwardTo=/"
            }}>{`Medipass portal`}</a><br />{`1. Go to ‘Business settings’ and navigate to the ‘API keys’ tab,`}<br />{`2. Select ‘Generate a new key’ and the API Key will be displayed. `}<br /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`App ID`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is your Tyro health  unique Identifier. Issued by our partner integration team. Please contact `}<a parentName="td" {...{
              "href": "mailto:partnerships@medipass.io"
            }}>{`partnerships@medipass.io`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`App Version`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is typically aligned to your software release version. Used for debug and support efforts. This value is determined by you.`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "environments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#environments",
        "aria-label": "environments permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Environments`}</h4>
    <p>{`Tyro Health has to two environments for partner integration:`}</p>
    <p>{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` 1. Non- Prod/ testing environment (STG):This represents a production like environment but uses test account details and stubs to simulate responses from funders.
`}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` 2.Production (PROD): Tyro Health production environment.`}</p>
    <h3 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h3>
    <p>{`There are two options to install theTyro Health transaction SDK:`}</p>
    <NumberLabel number="A" mdxType="NumberLabel">Installation via NPM/Yarn or,</NumberLabel>
    <NumberLabel number="B" mdxType="NumberLabel">Download the SDK via unpkg.com</NumberLabel>
    <blockquote>
      <p parentName="blockquote">{`Note: If you intend to use the SDK as a JavaScript module you will need to install the NPM/Yarn package. If you will be using the SDK with a `}<inlineCode parentName="p">{`<script>`}</inlineCode>{` tag or other URL direction method then you will need to install the unpkg.com version. `}</p>
    </blockquote>
    <h5 {...{
      "id": "numberlabel-numberainstallation-via-npmyarn-numberlabel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#numberlabel-numberainstallation-via-npmyarn-numberlabel",
        "aria-label": "numberlabel numberainstallation via npmyarn numberlabel permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><NumberLabel number="A" mdxType="NumberLabel">{`Installation via NPM/Yarn `}</NumberLabel></h5>
    <pre><code parentName="pre" {...{}}>{`npm install @medipass/partner-sdk
// or, with yarn:
yarn add @medipass/partner-sdk
`}</code></pre>
    <p>{`Or`}</p>
    <h5 {...{
      "id": "numberlabel-numberbdownload-the-sdk-via-unpkgcomnumberlabel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#numberlabel-numberbdownload-the-sdk-via-unpkgcomnumberlabel",
        "aria-label": "numberlabel numberbdownload the sdk via unpkgcomnumberlabel permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><NumberLabel number="B" mdxType="NumberLabel">{`Download the SDK via unpkg.com`}</NumberLabel></h5>
    <p><a parentName="p" {...{
        "href": "https://unpkg.com/@medipass/partner-sdk/umd/@medipass/partner-sdk.min.js"
      }}>{`https://unpkg.com/@medipass/partner-sdk/umd/@medipass/partner-sdk.min.js`}</a></p>
    <h5 {...{
      "id": "colouredtext-textcolourblueusing-the-javascript-modulecolouredtext",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#colouredtext-textcolourblueusing-the-javascript-modulecolouredtext",
        "aria-label": "colouredtext textcolourblueusing the javascript modulecolouredtext permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h5"><ColouredText textColour="blue" mdxType="ColouredText">{`Using the JavaScript Module`}</ColouredText></strong></h5>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import medipassSDK from '@medipass/partner-sdk';
// or: const medipassSDK = require('@medipass/partner-sdk');

medipassSDK.setConfig({
  env: 'stg',
  apiKey: '[insert your API key here]',
  appId: '[insert your App ID here]',
  appVersion: '[insert your App version here]'
});

medipassSDK.renderCreateTransaction({}, {
  onSuccess: function (transaction) {
    // handle successful submission of transaction
  },
  onError: function (error) {
    // handle errored submission of transaction
  },
  onCancel: function () {
    // handle when create transaction flow has been cancelled
  }
});
`}</code></pre>
    <h5 {...{
      "id": "colouredtext-textcolourblueusing-a-script-tagcolouredtext",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#colouredtext-textcolourblueusing-a-script-tagcolouredtext",
        "aria-label": "colouredtext textcolourblueusing a script tagcolouredtext permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h5"><ColouredText textColour="blue" mdxType="ColouredText">{`Using a `}<inlineCode parentName="strong">{`<script>`}</inlineCode>{` tag`}</ColouredText></strong></h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
<head>
  <script src="https://unpkg.com/@medipass/partner-sdk/umd/@medipass/partner-sdk.min.js"></script>
</head>
<body>
  <script>
    MedipassTransactionSDK.setConfig({
      env: 'stg',
      apiKey: '[insert your API key here]',
      appId: '[insert your App ID here]',
      appVersion: '[insert App version key here]'
    });

    MedipassTransactionSDK.renderCreateTransaction({}, {
      onSuccess: data => {
        // handle success
      },
      onError: data => {
        // handle error
      },
      onCancel: () => {
        // handle cancel
      }
    });
  </script>
</body>
</html>
`}</code></pre>
    <h3 {...{
      "id": "short-lived-sdk-token",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#short-lived-sdk-token",
        "aria-label": "short lived sdk token permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Short-lived SDK token`}</h3>
    <p>{`In order to mitigate potential risks of unauthorised API keys, Tyro Health provides the option of a short-lived SDK authentication token.`}</p>
    <p>{`To use the short-lived token you will need to follow the below three step process:`}</p>
    <NumberLabel number="step 1" mdxType="NumberLabel">On your server-side, use the Business Admin API key to call:</NumberLabel>
    <p><strong parentName="p">{`Resource`}</strong>{` `}<Highlight backgroundColour="green" mdxType="Highlight">{`POST`}</Highlight>{` `}<inlineCode parentName="p">{`<base-url>/v3/auth/token`}</inlineCode></p>
    <p>{`Where base-url:`}</p>
    <p>{`Staging: stg-api-au.medipass.io`}</p>
    <p>{`Production: api-au.medipass.io`}</p>
    <p><strong parentName="p">{`Required headers`}</strong></p>
    <ul>
      <li parentName="ul">{`authorization: `}<inlineCode parentName="li">{`Bearer <Business_Admin_API_key>`}</inlineCode></li>
      <li parentName="ul">{`x-appid: your App ID`}</li>
    </ul>
    <p><strong parentName="p">{`Payload`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`{
    "audience": "aud:business-sdk",
    "expiresIn": "1h"    // e.g. 30m, 1h, 6h, 24h. Defaults to 1h, max is 24h.
}
`}</code></pre>
    <NumberLabel number="step 2" mdxType="NumberLabel"> The response will contain a short-lived token that is restricted to SDK features. For example, it can't be used to generate more tokens, update business details or create other users in a business.</NumberLabel>
    <pre><code parentName="pre" {...{}}>{`{
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZXIiOjEsImlhdCI6MTU5Mzc1NzI1MCwiZXhwIjoxNTkzNzYwODUwLCJhdWQiOiJhdWQ6YnVzaW5lc3Mtc2RrIiwic3ViIjoiNTc0M2NiNWI1YjI0Y2MxNDAwOTk5MTcwIiwianRpIjoiNVctZDZLIn0.YbbogFjmk7-BT15aY7vqHfFcXpH5Smr9LT96hHUjlWQ"
}
`}</code></pre>
    <NumberLabel number="step 3" mdxType="NumberLabel">Return this token to your portal, and use it for the SDK apiKey field.</NumberLabel>
    <h4 {...{
      "id": "numberlabel-number2submit-transaction-detailsnumberlabel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#numberlabel-number2submit-transaction-detailsnumberlabel",
        "aria-label": "numberlabel number2submit transaction detailsnumberlabel permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><NumberLabel number="2" mdxType="NumberLabel">{`Submit transaction details`}</NumberLabel></h4>
    <h5 {...{
      "id": "required-transaction-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#required-transaction-details",
        "aria-label": "required transaction details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Required transaction details`}</h5>
    <p>{`All required claim or payment attributes such as practice, patient and charges are handled within the self-contained UI. Partners can also handle success, error and cancel events with an onSuccess, onError and onCancel callbacks.  Webhooks are recommended for asynchronous responses post submission. `}</p>
    <p>{`In the most basic mode, partners will be directed to the Tyro Health transaction SDK which prompts the user to enter all the required attributes to process the claim. In this mode, users are required to input all attributes of a transaction (even  if those details have already been entered in the partners system).  Once the transaction has been completed, the status and payment reconciliation references are returned which can be recorded in your system. `}</p>
    <p>{`By default, the SDK will prompt users to enter all the required attributes. However you have the option of  prepPopulating some or all `}<a parentName="p" {...{
        "href": "/sdk/comcare/comcare-attributes"
      }}>{`attributes required`}</a>{` from your system  with the Medipass UI acting as a simple validation and error handling.`}</p>
    <h4 {...{
      "id": "numberlabel-number3record-transaction-outcome-numberlabel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#numberlabel-number3record-transaction-outcome-numberlabel",
        "aria-label": "numberlabel number3record transaction outcome numberlabel permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><NumberLabel number="3" mdxType="NumberLabel">{`Record Transaction Outcome `}</NumberLabel></h4>
    <p>{`When a transaction is successful, the SDK will pass through the transaction details to the first parameter of the onSuccess hook.`}</p>
    <p>{`Please  refer to the `}<a parentName="p" {...{
        "href": "/sdk/comcare/response"
      }}>{`Transaction object response on Success`}</a>{` for a response payload attribute transaction. `}</p>
    <h4 {...{
      "id": "sdk-versioning",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#sdk-versioning",
        "aria-label": "sdk versioning permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SDK versioning`}</h4>
    <p>{`The content in the SDK modal will display the latest version of connect.`}</p>
    <p>{`Partners can also pass through the version of their application to the SDK via the `}<Highlight mdxType="Highlight">{`sdk.setConfig`}</Highlight>{` function,`}</p>
    <pre><code parentName="pre" {...{}}>{`For example:
sdk.setConfig({
  env: "stg",
  appVersion: "3.51.0",
  token: "myToken",
  appId: "my-app-id"
});
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      